









































































































import { Vue, Component } from "vue-property-decorator";
import {
  InfoUsuario,
  ObtenerInfoUsuario,
  ChangePassword,
  ProfileUpdate,
  ChangePasswordModel,
} from "@/entidades/Maestro/Sistema/UsuarioInfo";
import { State, Action, Mutation } from "vuex-class";
import Loading from "@/entidades/Sistema/Loading";
import Alerta from "@/entidades/Sistema/Alerta";
@Component
export default class ProfileUser extends Vue {
  @Mutation("usuarioLogout", { namespace: "authModule" })
  usuarioLogout!: Function;
  @Action("changeLoading", { namespace: "sistemaModule" })
  changeLoading!: Function;
  @Action("changeAlerta", { namespace: "sistemaModule" })
  changeAlerta!: Function;
  @State("usuarioId", { namespace: "authModule" }) usuarioId!: string;
  perfil: string = "";
  sistema: string = "";
  rol: string = "";
  planta: string = "";
  password: string = "";
  firstName: string = "";
  lastName: string = "";
  userName: string = "";
  email: string = "";
  passwordConfirm: string = "";

  async profileUser() {
    try {
      this.changeLoading(new Loading(true, "Actualizando usuario"));
      var user = new InfoUsuario();
      user.usuarioId = this.usuarioId;
      user.userName = this.userName;
      user.firstName = this.firstName;
      user.lastName = this.lastName;
      user.email = this.email;
      var response = await ProfileUpdate(user);
      if (response.isSuccess == true) {
        this.changeAlerta(
          new Alerta(response.isMessage, true, "success", 1000)
        );
        this.logout();
      }

      this.changeAlerta(new Alerta(response.isMessage, true, "success", 3000));
    } catch (error) {
    } finally {
      this.changeLoading(new Loading(false, ""));
    }
  }

  async changePassowrdLocal() {
    try {
      this.changeLoading(new Loading(true, "Actualizando usuario"));
      var user = new ChangePasswordModel(this.usuarioId, this.password);
      var response = await ChangePassword(user);
      if (response.isSuccess == true) {
        this.changeAlerta(
          new Alerta(response.isMessage, true, "success", 1000)
        );
        this.logout();
      }

      this.changeAlerta(new Alerta(response.isMessage, true, "success", 3000));
    } catch (error) {
    } finally {
      this.changeLoading(new Loading(false, ""));
    }
  }

  logout() {
    this.usuarioLogout();
    this.$router.replace("/login");
  }

  get validarPassword() {
    if (this.password.length > 0 && this.passwordConfirm.length > 0) {
      if (this.password === this.passwordConfirm) return true;
    }
    return false;
  }

  async mounted() {
    var rsp = await ObtenerInfoUsuario(this.usuarioId);
    this.perfil = rsp.isResult!.perfil;
    this.rol = rsp.isResult!.rol;
    this.firstName = rsp.isResult!.firstName;
    this.lastName = rsp.isResult!.lastName;
    this.planta = rsp.isResult!.planta;
    this.sistema = rsp.isResult!.sistema;
    this.userName = rsp.isResult!.userName;
    this.email = rsp.isResult!.email;
    
  }
}
